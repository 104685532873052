import styled from "styled-components";

const Wrapper = styled.div`
  .thumb {
    width: 100%;
    height: 180px;
    object-fit: cover;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    transition: all 0.3s linear;
    border-radius: 0.25rem 0.25rem 0 0;
  }

   .thumb:hover {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  }

  .truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces;
    height: 44px;
  }

  .truncate-description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces;
    height: 46px;
  }

  .video-info h4 {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .video-info p {
    font-size: 0.8rem;
    font-weight: 100;
    line-height: 1.3;
    letter-spacing: 1px;
  }

  .leading-4 {
    line-height: 1;
  }

  .video-info-container {
    display: flex;
    background-color: ${(props) => props.theme.lightNavyBlue};
    border-radius: 0 0 0.25rem 0.25rem;
    box-shadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
    transition: all 0.3s linear;
  }

  .video-info-container:hover {
    box-shadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
  }

  .channel-avatar img {
    position: relative;
    top: 5px;
    margin-left: 0.3rem;
  }

  .video-info {
    flex: 1 1 0;
  }
  .video-title {
    margin-top: 0.3rem;
    padding-left: 0.3rem;
  }

  .video-description {
    padding-left: 0.3rem;
  }

  .user-info {
    padding-left: 0.3rem;
  }

  .video-info span {
    font-size: 0.9rem;
    padding-right: 0.1rem;
  }

  @media screen and (max-width: 600px) {
    .thumb {
      height: 250px;
    }
  }

  @media screen and (max-width: 420px) {
    .thumb {
      height: 200px;
    }
  }
`;

export default Wrapper;
