import React from "react";
import imgCover from "../assets/img/img-bg.jpg";
import imgOne from "../assets/img/img-1.jpg";
import imgTwo from "../assets/img/img-2.jpg";
import imgThree from "../assets/img/img-3.jpg";
import imgFour from "../assets/img/img-4.jpg";
import "../styles/CustomStyle.css";

function AboutUs() {
  return (
      <div>
          
    <section className="slice py-8 bg-dark bg-cover bg-size--cover" style={{backgroundImage: "url(" + imgCover + ")"}}>
        <span className="mask bg-gradient-dark opacity-9"></span>
        <div className="container d-flex align-items-center text-center text-lg-left pt-5 pb-5 pt-lg-6">
            <div className="col px-0">
                <div className="row row-grid align-items-center">
                    <div className="col-lg-8 text-center text-lg-left">
                        <h1 className="text-white mb-4">
                            MonBonCoin is a new advertising solution for businesses to raise brand awareness and increase sales by promoting videos of their products and services.
                        </h1>
                        <p className="lead text-white opacity-8">
                            We are deeply passionate about working with local and small businesses in helping them grow their online presence and reaching more potential customers. That is why we created a new alternative platform where businesses can promote their products and services to millions of consumers.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="slice slice-lg">
        <div className="container">
            <span className="badge badge-primary badge-pill">Our vision</span>
            <div className="row mt-4">
                <div className="col-lg-6 pr-lg-5">
                    <p className="h5 lh-180 mb-3">
                        Our vision is to become the platform by default that serves as a repertoire for all sorts of businesses, products, and services. We want to create a unique and efficient way to connect businesses with consumers.
                    </p>
                </div>
                <div className="col-lg-6">
                    <p className="lead lh-180">
                        Video ads boost conversions and help increase sales. Video are more engaging to viewers and especially effective when used for product demonstrations as viewers can actually see how certain products work. It incorporates audio and visual elements that appeal to multiple senses and catch user attention. Consumers are 27.4 times more likely to click-through online video ads than standard banners. Because of these facts, we understood this is the way we wanted to help businesses connect to their audience.
                    </p>
                    <p className="lead lh-180">
                        MonBonCoin is helping businesses in New York City increase their online presence and reach more potential customers.
                    </p>
                </div>
            </div>
        </div>
    </section>
    <section className="slice slice-lg">
        <div className="container">
            <div className="row">
                <div className="col-lg-5 col-6">
                    <img src={imgOne} alt="Image" className="rounded-lg img-fluid" />
                    <div className="text-right mt-4">
                        <img src={imgTwo} alt="Image" className="rounded-lg img-fluid w-50" />
                    </div>
                </div>
                <div className="col-lg-7 col-6">
                    <img src={imgThree} alt="Image" className="rounded-lg img-fluid w-75" />
                    <div className="text-right mt-4">
                        <img src={imgFour} alt="Image" className="rounded-lg img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    </section>
      </div>
  );
}

export default AboutUs;
