// @ts-nocheck
import styled, { css } from "styled-components";

const Button = styled.button`
  padding: 10px 16px;
  font-family: ${(props) => props.font};
  background: ${(props) => props.theme.yellow};
  // color: ${(props) => props.theme.black};
  color: ${(props) => props.theme.navyBlue};
  border: 1px solid ${(props) => props.theme.yellow};
  border-radius: 1.5rem;
  font-weight: 400;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0.02857em;

  @media screen and (max-width: 400px) {
    font-size: 14px;
    padding: 5px 8px;
  }

  ${(props) =>
    props.grey &&
    css`
      // background: ${(props) => props.theme.darkGrey};
      background: ${(props) => props.theme.thirdColor};
      // border: 1px solid ${(props) => props.theme.darkGrey};
      border: 1px solid ${(props) => props.theme.thirdColor};
      // color: ${(props) => props.theme.secondaryColor};
      color: ${(props) => props.theme.fourthColor};
    `}
`;

export default Button;
