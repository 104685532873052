import React from "react";
import Wrapper from "../styles/Navbar";
import GoogleAuth from "./GoogleAuth";
import { NavLink } from "react-router-dom";
import logo from "../logo.svg";
import { AppsIcon, HamburgerIcon, VidIcon, SettingsIcon } from "./Icons";
import Search from "./Search";
import { useAuth } from "../context/auth-context";
import UserDropdown from "./UserDropdown";
import UploadVideo from "./UploadVideo";

function Navbar({ toggleSidebarOpen }) {
  const user = useAuth();

  return (
    <Wrapper>
      <div className="logo flex-row">
        <HamburgerIcon
          onClick={toggleSidebarOpen}
          className="toggle-navhandler"
        />
        <span>
          <NavLink to="/">
            <img src={logo} alt="MonBonCoin Logo"  style={{
                width: 80,
                height: 48,
              }}/>
          </NavLink>
        </span>
      </div>

      <Search />

      <ul>
        <li>{user ? <UploadVideo /> : <NavLink to="/feed/my_videos" activeClassName="active">
        <div className="icon">
          <span>Promote <br />Your Business</span>
        </div>
      </NavLink>}</li>
        <li>{user ? <UserDropdown user={user} /> : <GoogleAuth />}</li>
      </ul>
    </Wrapper>
  );
}

export default Navbar;
