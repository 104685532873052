import React from "react";
import illustrationThirteen from "../assets/img/svg/illustrations/illustration-13.svg";
import "../styles/CustomStyle.css";

function NotFound() {
  return (
    <div>
      <section>
        <div className="container d-flex flex-column">
            <div className="row align-items-center justify-content-between min-vh-100">
                <div className="col-12 col-md-6 col-xl-7 order-md-2">
                    <img alt="Error page illustration" src={illustrationThirteen} className="img-fluid" />
                </div>
                <div className="col-12 col-md-6 col-xl-5 order-md-1 text-center text-md-left">
                    <h6 className="display-1 mb-3 font-weight-600 text-warning">Ooops!</h6>
                    <p className="lead text-lg mb-5">
                        The page you are looking for could not be found. Sorry about that.
                    </p>
                </div>
            </div>
        </div>
    </section>
    </div>
  );
}

export default NotFound;
